import styled from 'styled-components';

export const Button = styled.button`

${props => props.block ? 'display: block; width: 100%' : ''};

&:hover{
  background: rebeccapurple;
  color: white;
}
`

export default Button;
