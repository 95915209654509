import React, { useState } from 'react'
// import { FirebaseContext } from '../components/Firebase'
import { Form, Input, Error } from '../components/common'
import { Helmet } from 'react-helmet';
import * as BS from "react-bootstrap"
import "bootstrap/dist/css/bootstrap-grid.css"

const Login = () => {

  const [formValues, setFormValues] = useState({ email: '', password: '' });
  // const { firebase } = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  
  // let isMounted = true;

  // useEffect(() => {
  //   return () => {
  //     isMounted = false;
  //   }
  // }, [])

  function handleSubmit(e) {
    e.preventDefault();

    // firebase.login({ email: formValues.email, password: formValues.password }).catch(error => {
    //   if (isMounted) {
    //     console.log(error)
    //     setErrorMessage(error.message);
    //   }
    // })
  }

  function handleInputChange(e) {
    e.persist()
    setErrorMessage('');
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <section>
      <Helmet>

        <title>Login to get access to your Unicorn Alert pro account!</title>
        <description>Get the latest updates from startups, investors and fundraising in your realtime Unicorn Alert dashboard.</description>
      </Helmet>

      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400}}>
        <BS.Row style={{textAlign: 'center', marginTop: 70}}>
        <BS.Col sm={12}>
      <h2 style={{color: '#34383b', fontWeight: 300, marginTop: 0}}>Login</h2>
      <Form onSubmit={handleSubmit}>
        <Input required value={formValues.email} name="email" onChange={handleInputChange} placeholder="email" type="email" />
        <Input required value={formValues.password} name="password" onChange={handleInputChange} placeholder="password" type="password" />
        {!!errorMessage &&
          <Error>
            {errorMessage}
          </Error>
        }
        <BS.Button type="submit" block className="btn-lg">Login</BS.Button>
      </Form>
        </BS.Col>
        </BS.Row>
      </BS.Container>

    </section >
  )
}

export default Login;